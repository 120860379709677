<template>
  <div>
    <CRow class="text-center">
      <FlashMessage :position="'right top'" style="z-index: 2000;"></FlashMessage>
      <div class="btn-sidebar-radar" @click="showlegend" :style="isLegend?'right: 20vw;':'right: 0vw;'">
        <v-icon :name="isLegend?'caret-right':'caret-left'" style="height: 100%; vertical-align: middle;"/>
      </div>
      <div v-if="isLegend" class="sidebar-radar">
        <CCol xl="12" style="text-align: center;" v-if="isHLoading">
          <CSpinner
            color="info"
            style="width:1rem;height:1rem;"
          />
        </CCol>
        <CCol xl="12" class="" v-if="!isHLoading">
          <CRow class="margin-0">
            <CCol tag="label" xl="12" class="col-form-label font-weight-bold padding-0" style="text-align: left;">
              RADAR Lanud:
            </CCol>
            <CCol xl="12" class="padding-0">
              <v-select 
                  :reduce="airports => airports.value"
                  v-model="airport_id"
                  :options="airports"
                  placeholder="Please select air base"
                  style="background-color: #FFF;"
                  @input="onChangeAirbase"
              />
            </CCol>
            <CCol xl="12" class="padding-0"><small class="form-text text-muted w-100">{{errors.airport_id}}</small></CCol>
          </CRow>
        </CCol>
        <CCol xl="12" class="mt-2">
          <CRow class="margin-0">
            <CCol tag="label" xl="12" class="col-form-label font-weight-bold padding-0" style="text-align: left;">
              Delay:
            </CCol>
            <CCol xl="12" class="padding-0">
              <v-select 
                  :reduce="delays => delays.value"
                  v-model="delay"
                  :options="delays"
                  placeholder="Please select delay"
                  style="background-color: #FFF;"
              />
            </CCol>
          </CRow>
        </CCol>
        <CCol xl="12" class="mt-2">
          <carousel :per-page="1" :loop="true" easing="ease-in-out"
            :autoplay="true" :autoplayTimeout="delay" :paginationEnabled="false" 
            :mouseDrag="false" :speed="0" :value="0" :centerMode="true" class="col-xl-12">
            <slide v-for="(maplg, idl) in legenddata.slice().reverse()" :key="idl">
              <img :src="maplg" alt="" style="width: 70%;">
            </slide>
          </carousel>
        </CCol>
      </div>
      <CCol xl="12" style="text-align: center;" v-if="isRefresh">
        <CSpinner
          color="info"
          style="width:3rem;height:3rem;"
        />
      </CCol>
      <CCol xl="12" class="padding-0" v-if="radardata.length>0">
        <panZoom :options="{minZoom: 1, maxZoom: 5}" @panstart="onPanStart" @zoom="onZoom" class="text-center" style="overflow: hidden; height: 90vh;">
          <carousel :per-page="1" :loop="true" easing="ease-in-out"
            :autoplay="true" :autoplayTimeout="delay" :paginationEnabled="false" 
            :mouseDrag="false" :speed="0" :value="0" :centerMode="true" :class="{'nonetrans': ispanzoom}">
            <slide v-for="(map, idx) in radardata.slice().reverse()" :key="idx">
              <img :src="map" alt="" class="img-fluid" style="width: 100%;">
            </slide>
          </carousel>
        </panZoom>
      </CCol>
      <CCol xl="12" class="mt-4 mb-2 padding-0">
        <span style="font-size: 1.3rem;">Latest Radar</span>
      </CCol>
      <!-- <CCol xl="6" class="mt-4 mb-2 padding-0">
        <span style="font-size: 1.3rem;">Display Radar</span>
      </CCol> -->
      <CCol xl="12" class="padding-0" v-if="latest_radardata.length>0">
        <panZoom :options="{minZoom: 1, maxZoom: 5}" @panstart="latest_onPanStart" @zoom="latest_onZoom" class="text-center" style="overflow: hidden; height: 90vh;">
          <carousel :per-page="1" :loop="true" easing="ease-in-out"
            :autoplay="true" :autoplayTimeout="delay" :paginationEnabled="false" 
            :mouseDrag="false" :speed="0" :value="0" :centerMode="true" :class="{'nonetrans': latest_ispanzoom}">
            <slide v-for="(map, idx) in latest_radardata" :key="idx">
              <img :src="map" alt="" class="img-fluid" style="width: 100%;">
            </slide>
          </carousel>
        </panZoom>
      </CCol>
      <CCol v-if="radardata.length==0" xl="12" class="padding-0 text-center mt-3"><span>Not Found</span></CCol>
    </CRow>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment'
import { Carousel, Slide } from 'vue-carousel';

export default {
  name: 'Dashboard',
  components: {
    Carousel,
    Slide
  },
  data () {
    return {
      isLoading: false, isHLoading: false, isRefresh: false, isLegend: false,
      ispanzoom: true,
      latest_ispanzoom: true,
      timestamp: '',
      airports: [],
      radardata: [],
      imagedata: [],
      latest_radardata: [],
      legenddata: ['storage/legendcmax.png'],
      airport_id: '',
      delay: 3000,
      form: {
        airport_id: ''
      },
      errors: {
        airport_id: ''
      },
      delays: [
        { value: 1000, label: 'Fast' },
        { value: 3000, label: 'Normal' },
        { value: 6000, label: 'Slow' }
      ]
    }
  },
  watch: {
    airport_id: {
        handler: function (val, oldVal) {
          if(val!=oldVal) { 
            this.isRefresh = true
            this.ispanzoom = true
            this.latest_ispanzoom = true
          }
        }
    },
    delay: {
        handler: function (val, oldVal) {
          if(val!=oldVal) { 
            var rd = this.radardata
            this.radardata = [null]
            this.radardata = rd
          }
        }
    },
  },
  created() {
    this.onLoad()
    this.showUserconfig()
    // this.getAirport()
  },
  beforeDestroy() {
    clearInterval(this.intervalTime)
    clearInterval(this.intervalRADAR)
  },
  methods: {
    ...mapActions('airport', ['indexAPT']),
    ...mapActions('profile', ['showPFL']),
    ...mapActions('dashboard', ['storeRADAR']),
    ...mapActions('configs', ['indexUCFG']),
    onLoad() {
      this.intervalTime = setInterval(this.getNow, 1000)
      this.intervalRADAR = setInterval(this.postRADAR, 60000)
    },
    async getAirport(){
      this.isHLoading = true
      await this.showPFL().then((response) => {
          response.airport_rights.forEach((item, index) => {
              this.airports[index] = { value: item.id, label: item.name+" ("+item.code+")" }
          })
          this.isHLoading = false
          if(response.airport_rights.length>0) {
            this.airport_id = this.airports[0].value
            this.form.airport_id = this.airports[0].value
            this.postRADAR()
          }
      }).catch((error) => {
          this.flashMessage.error({ title: 'Error', message: error })
          this.isHLoading = false
      })
    },
    async postRADAR(){
      if(this.form.airport_id) {
        this.isLoading = true
        if(this.isRefresh){
          this.radardata = []
          this.latest_radardata = []
          this.legenddata = ['storage/legendcmax.png']
        }
        await this.storeRADAR(this.form).then((response) => {
          this.radardata = response.map(function (obj) { return obj.image_radar })
          this.imagedata = response.map(function (obj) { return obj.image })
          this.latest_radardata = this.imagedata.length>0 ? [this.imagedata[0]] : []
          this.legenddata = response.map(function (obj) { return obj.image_legend })
          console.log(this.latest_radardata)
          if(this.legenddata<1) { this.legenddata = ['storage/legendcmax.png'] }
          this.isRefresh = false
          this.isLoading = false
        }).catch((error) => {
          this.flashMessage.error({ title: 'Error', message: error.statusText })
          this.getError(error.data)
          this.isLoading = false
        })
      }
    },
    showUserconfig() {
      this.isHLoading = true
      this.indexUCFG().then((response) => {
        var ind = 0
        response.available_airports.forEach((item, index) => {
          if(item.have_radar == 1){
            this.airports[ind] = { value: item.id, label: item.name+" ("+item.code+")" }
            ind++
          }
        })
        /* Radar */
        this.airport_id = response.radar_selected ? response.radar_selected.id:''
        this.form.airport_id = response.radar_selected ? response.radar_selected.id:''
        this.delay = response.radar_delay?response.radar_delay:3000
        if(this.form.airport_id<1) {
          this.airport_id = this.airports[0].value
          this.form.airport_id = this.airports[0].value
        }
        this.isHLoading = false
        this.postRADAR()
      }).catch((error) => {
        this.flashMessage.error({ title: 'Error', message: error })
        this.isHLoading = false
      })
    },
    onChangeAirbase(v) {
      this.airport_id = v
      this.form.airport_id = v
      this.postRADAR()
    },
    getNow() {
      this.timestamp = moment().locale('id').format('DD MMMM YYYY hh:mm')
    },
    showlegend() {
      this.isLegend = !this.isLegend;
    },
    onPanStart(e) {
      this.ispanzoom = false
    },
    onZoom(e) {
      this.ispanzoom = false
    },
    latest_onPanStart(e) {
      this.latest_ispanzoom = false
    },
    latest_onZoom(e) {
      this.latest_ispanzoom = false
    },
    getError(data){
      if(typeof(data) == 'object' && data){ if(data.errors) { 
        if(data.errors) { this.errors.airport_id = data.errors.airport_id ? data.errors.airport_id[0] : '' }
      } else { this.errors = { airport_id: '' }} }
    }
  }
}
</script>
